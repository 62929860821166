/**
 * Sticky Header
 * Adds a class to header on scroll
 */

jQuery( document ).ready( function( ) {
	/**
	 * Search Script
	 */

	/*
        *
        *   * Header Buttons dropdowns
        *
        * */

	/*
        * Close popup on anywhere click
        */

	// jQuery( document ).mouseup( function( e ) {
	// 	const container = jQuery( '.bln-select-dd-menus' );
	// 	const dropdowns = jQuery( '.bln-select-dropdown' );
	// 	dropdowns.each( function() {
	// 		const dropdown = jQuery( this );
	// 		const button = dropdown.find( '.bln-select-dropdown-button' );
	// 		const list = dropdown.find( '.bln-select-dd-menus' );
	// 		if ( ! list.is( e.target ) && list.has( e.target ).length === 0 && ! button.is( e.target ) && button.has( e.target ).length === 0 ) {
	// 			list.removeClass( 'is-active-dropdown' );
	// 			button.removeClass( 'is-button-opened' );
	// 		}
	// 	} );
	// } );
	let blnMenuCat;

	blnMenuCat = jQuery( '#bln-menu-cat' ).CustomSelect( {
		prefix: 'bln',

	} );

	const blnEventType = jQuery( '#bln-form-select-event-type' ).CustomSelect( {
		prefix: 'bln',
	} );
	const blnMonth = jQuery( '#bln-form-select-month' ).CustomSelect( {
		prefix: 'bln',
	} );

	jQuery( document ).on( 'click', '.bln-events-form .bln-search-submit,.events-ajax-item', function( e ) {
		setTimeout( () => {
			e.preventDefault();
			const form = jQuery( this ).parents( '.bln-events-form' );
			let eventType = blnEventType.getValue();
			let month = blnMonth.getValue();
			const search = form.find( '.bln-search-input' ).val();
			if ( typeof month === 'undefined' ) {
				month = '';
			}
			if ( typeof eventType === 'undefined' ) {
				eventType = '';
			}
			const is_slider = jQuery( '.bln-events-archive-inner' ).attr( 'data-slider' );

			// console.log( eventType, month, search );
			const items = {
				eventType,
				month,
				search,
			};
			// console.log( items );

			if ( history.pushState ) {
				const oldurl = window.location.protocol + '//' + window.location.host + window.location.pathname;
				let newurl = '';
				let count = 0;
				for ( let key in items ) {
					const element = items[ key ];
					if ( key === 'eventType' ) {
						key = 'event-type';
					}
					// console.log( element === '' );

					if ( element !== '' || typeof element !== 'undefined' ) {
						if ( count == 0 ) {
							newurl = oldurl + '?';
							newurl += key + '=' + element;
						} else {
							newurl += '&' + key + '=' + element;
						}
					}
					count++;
				}

				window.history.pushState( { path: newurl }, '', newurl );
			}
			jQuery( '.bln-lds-roller' ).show();
			jQuery( '.bln-events-archive-inner' ).addClass( 'bln-overlay' );
			jQuery.ajax( {
				url: BlnVars.ajax_url,
				type: 'post',
				data: {
					action: 'events_archive_ajax',
					items,
					is_slider,
					nonce: BlnVars.nonce,
				},
				success( response ) {
					jQuery( '#bln-filter-ajax-reponse' ).html( response );

					jQuery( '.bln-four-columns' ).each( function() {
						if ( jQuery( this ).children().length == 0 ) {
							jQuery( this ).parents( '.bln-events-ctn' ).remove();
						}
					} );
					if ( jQuery( '#bln-filter-ajax-reponse' ).children().length == 0 ) {
						jQuery( '#bln-filter-ajax-reponse' ).html( '<div class="bln-not-found-message bln-mb10 bln-text-uppercase bdsans bln-text20 bln-center-text-align">No Events Found</div>' );
					}

					jQuery( '.bln-lds-roller' ).hide();
					jQuery( '.bln-events-archive-inner' ).removeClass( 'bln-overlay' );
					jQuery( '.bln-event-slider' ).each( function( index, element ) {
						const $slider = jQuery( element );
						const $counter = jQuery( '<div class="bln-event-slider-counter bdsans-black"></div>' );
						$slider.after( $counter );

						$slider.on( 'initialized.owl.carousel changed.owl.carousel', function( e ) {
							if ( ! e.namespace ) {
								return;
							}
							const carousel = e.relatedTarget;
							const currentIndex = carousel.relative( carousel.current() ) + 1;
							const totalItems = carousel.items().length;

							// Format currentIndex and totalItems with leading zero if less than 10
							const formattedIndex = ( currentIndex < 10 ) ? '0' + currentIndex : currentIndex;
							const formattedTotal = ( totalItems < 10 ) ? '0' + totalItems : totalItems;

							$counter.text( formattedIndex + ' / ' + formattedTotal );
						} ).owlCarousel( {
							center: false,
							items: 3,
							slideBy: 1,
							autoWidth: false,
							loop: false,
							margin: 30,
							nav: true,
							autoHeight: true,
							dots: false,
							autoplay: false,
							autoplayTimeout: 5000,
							autoplayHoverPause: true,
							responsive: {
								280: {
									items: 1,
									// stagePadding: 30,
									margin: 20,
								},
								747: {
									items: 2,
									// stagePadding: 30,
									margin: 24,
								},
								1003: {
									items: 3,
								},
							},
						} );
					} );
				},
			} );
		} );
	} );

	jQuery( document ).on( 'click', '.ajax-drink-item', function() {
		setTimeout( () => {
			const slug = blnMenuCat.getValue();
			const menu = jQuery( '#bln-drink-menu' ).attr( 'data-slug' );
			const class_name = jQuery( '#bln-drink-menu' ).attr( 'data-class' );
			const label = jQuery( '#bln-drink-menu' ).attr( 'data-label' );
			if ( history.pushState ) {
				const oldurl = window.location.protocol + '//' + window.location.host + window.location.pathname;
				let newurl = oldurl + '?';
				newurl += 'drink-tag=' + slug;

				window.history.pushState( { path: newurl }, '', newurl );
			}
			jQuery( '.bln-lds-roller' ).show();
			jQuery( '.bln-menu-category-top-level' ).addClass( 'bln-overlay' );
			jQuery.ajax( {
				url: BlnVars.ajax_url,
				type: 'post',
				data: {
					action: 'drink_menu_ajax',
					slug,
					menu,
					label,
					class_name,
					nonce: BlnVars.nonce,
				},
				success( response ) {
					jQuery( '#drink-response-ajax' ).replaceWith( response );
					if ( jQuery( window ).width() < 747 ) {
						blnMenuCat = jQuery( '#bln-menu-cat' ).CustomSelect( {
							prefix: 'bln',

						} );
					} else {
						blnMenuCat = jQuery( '#bln-menu-cat' ).CustomSelect( {
							prefix: 'bln',
							type: 'button',
						} );
					}
				},
			} );
		}, 100 );
	} );

	jQuery( document ).on( 'click', '.ajax-food-item', function() {
		setTimeout( () => {
			const slug = blnMenuCat.getValue();
			const menu = jQuery( '#bln-food-menu' ).attr( 'data-slug' );
			const class_name = jQuery( '#bln-food-menu' ).attr( 'data-class' );
			const label = jQuery( '#bln-food-menu' ).attr( 'data-label' );
			if ( history.pushState ) {
				const oldurl = window.location.protocol + '//' + window.location.host + window.location.pathname;
				let newurl = oldurl + '?';
				newurl += 'food-tag=' + slug;

				window.history.pushState( { path: newurl }, '', newurl );
			}
			jQuery( '.bln-lds-roller' ).show();
			jQuery( '.bln-menu-category-top-level' ).addClass( 'bln-overlay' );
			jQuery.ajax( {
				url: BlnVars.ajax_url,
				type: 'post',
				data: {
					action: 'food_menu_ajax',
					slug,
					menu,
					label,
					class_name,
					nonce: BlnVars.nonce,
				},
				success( response ) {
					jQuery( '#food-response-ajax' ).replaceWith( response );
					blnMenuCat = jQuery( '#bln-menu-cat' ).CustomSelect( {
						prefix: 'bln',

					} );
				},
			} );
		}, 100 );
	} );

	jQuery( document ).on( 'keydown', '.bln-search-input', function( e ) {
		if ( parseInt( e.which ) === 13 ) {
			e.preventDefault();
			jQuery( this ).parents( '.bln-events-form' ).find( '.bln-search-submit' ).click();
		}
	} );
} );
